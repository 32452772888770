document.addEventListener("DOMContentLoaded", function (event) {

    // const sHeros = document.querySelectorAll('.s-hero');
    const sHeros = document.querySelectorAll('.js-hero-slider');

    if (sHeros) {
        sHeros.forEach((hero) => {
            const swiper = hero.querySelector('.s-hero__swiper');
            if (swiper) {
                new Swiper(swiper, {
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    loop: true,
                    effect: 'fade',
                    speed: 2500,
                    pagination: {
                        el: hero.querySelector('.s-hero__pagination'),
                        type: 'bullets',
                        clickable: true
                    },
                    autoplay: {
                        delay: 5000
                    }
                });
            }
        });
    }

});